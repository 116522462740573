import React from 'react';
import { Img } from './img';
import Link from '../utils/link';

function clickFlagLink(lang) {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem('language', lang);
  }
}

export const Flag = ({ country, text, lang, to, flag, noSecondLang }) => {
  const className = country === lang ? 'active-lang' : '';
  const reverseLang = country === 'en' ? 'pl' : 'en';
  if (lang === reverseLang && noSecondLang) {
    return <></>;
  }
  return (
    <li>
      <Link
        className={className}
        to={to.replace('//', '/')}
        hrefLang={country}
        onClick={() => {
          clickFlagLink(country);
        }}
      >
        <Img image={flag} alt={text} />
      </Link>
    </li>
  );
};
